import * as React from "react"
import VerifyChangeEmail from "@components/AccountSettings/Email/VerifyChangeEmail"
import Seo from "@components/seo"

const VerifyChangeEmailPage = ({ location }) => (
  <>
    <Seo title="Verify Change Email" />
    <VerifyChangeEmail location={location} />
  </>
)

export default VerifyChangeEmailPage
