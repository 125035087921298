import React, { useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import qs from "query-string"
import { detectMob } from "@src/Helper"
import { verifyChangeEmail } from "@http/EmailPasswordRepository"

const VerifyChangeEmail = ({ location }) => {
  // Custom Functions
  const handleVerifyChangEmail = token => {
    verifyChangeEmail(token)
      .then(res => {
        console.log(res.status)
      })
      .catch(err => console.log(err))
  }

  // Hooks
  useEffect(() => {
    const queryString = qs.parse(location.search)
    handleVerifyChangEmail(queryString.token)
  }, [])

  // JSX
  if (detectMob()) {
    return (
      <div id="m-verifyEmail" className="verify-email">
        <div className="container">
          <div className="row">
            <div className="flex-col col-12">
              <StaticImage
                className="verify-email__icon"
                src="../../../images/success-icon.svg"
                alt="Link Sent"
                width={58}
                height={58}
              />
              <h1 className="verify-email__title heading-3">
                Verifikasi Sukses
              </h1>
              <p className="verify-email__description body">
                Selamat! Email kamu sudah berhasil terverifikasi. Silahkan
                lanjutkan ke Dasbor untuk kembali menggunakan Sociopipe.
              </p>
              <Link
                className="verify-email__button button button-md button-primary"
                to="/dashboard"
              >
                Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="verify-email">
        <div className="container pad-t-175">
          <div className="row">
            <div className="flex-col col-6 offset-3">
              <StaticImage
                className="verify-email__icon"
                src="../../../images/success-icon.svg"
                alt="Link Sent"
                width={73}
                height={73}
              />
              <h1 className="verify-email__title heading-3">
                Verifikasi Sukses
              </h1>
              <p className="verify-email__description body">
                Selamat! Email kamu sudah berhasil terverifikasi. Silahkan
                lanjutkan ke Dasbor untuk kembali menggunakan Sociopipe.
              </p>
              <Link
                className="verify-email__button button button-md button-primary"
                to="/dashboard"
              >
                Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VerifyChangeEmail
